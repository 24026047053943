'use client'

import dynamic from 'next/dynamic'

import { Destination, Place, Category } from '@/types'
import { WeatherData } from '@/types/weather'

import { CostOfLivingData } from '../CostofLivingVisualization'

const ClientDestinationPage = dynamic(
  () => import('./ClientDestinationPage'),
  { ssr: false }
)

interface DestinationPageWrapperProps {
  destinationData: Destination
  initialPlaces: Place[]
  categories: Category[]
  userFavorites: any[]
  destination: string
  relatedDestinations: any[]
  weatherData: WeatherData | null
  costOfLivingData: CostOfLivingData | null | undefined
  children?: React.ReactNode
}

export default function DestinationPageWrapper(props: DestinationPageWrapperProps) {
  return <ClientDestinationPage {...props} />
} 